import { ContentLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';

import { Head } from 'components/Head';
import { useNavigate } from 'react-router-dom';
import EmptySvg from 'assets/Empty-cuate.svg';
// import { Button } from 'components/Elements';
import { MdWarning } from 'react-icons/md';

export const Dashboard = () => {
  const { user } = useAuth();

  const navigate = useNavigate();

  return (
    <ContentLayout
      avatar={user.basicInformation?.avatar}
      title={`Welcome ${user.basicInformation?.firstName}`}
    >
      <Head title="FinablrSign" />
      <div className="flex flex-col items-center justify-center">
        <img src={EmptySvg} alt="empty" className="h-60" />
        <div className="contract mb-2 flex flex-row items-center space-x-2">
          <MdWarning />
          <span>Contracts system is currently offline. Check back later.</span>
        </div>
        {/* <Button>Get Notified</Button> */}
      </div>
    </ContentLayout>
  );
};
