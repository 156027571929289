import React from 'react';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';

export const WebLayout = ({ children }) => {
  return (
    <div>
      <Navbar />
      <div className="bg-gray-100 p-8 md:p-24">{children}</div>
      <Footer />
    </div>
  );
};
