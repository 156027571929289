import PropTypes from 'prop-types';
import * as React from 'react';

import { Head } from '../Head';
import { UPLOADS_URL } from 'config';

export const ContentLayout = ({ avatar, children, title, end }) => {
  return (
    <>
      <Head title={title} />
      <div className="">
        {title && (
          <div
            className={` bg-gray-800 px-4 sm:px-6 md:px-8 py-4 flex md:flex-row items-center ${
              end ? 'justify-between' : 'justify-center'
            }`}
          >
            {avatar && (
              <div>
                <img
                  src={UPLOADS_URL + '/images/users/' + avatar}
                  className="rounded-full h-12 w-12 shadow"
                />
              </div>
            )}
            <div className="text-md contract md:text-xl p-4 font-semibold text-start w-full text-gray-100">
              {title}
            </div>
            <div>{end}</div>
          </div>
        )}
        <div className="max-w-7xl mx-auto px-4 mt-4 py-4">{children}</div>
      </div>
    </>
  );
};

ContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  end: PropTypes.node,
};
