import { Button, Link } from 'components/Elements';
import { Head } from 'components/Head';
import PropTypes from 'prop-types';
import logo from 'assets/logo.svg';

export const Layout = ({ children, title }) => {
  return (
    <>
      <Head title={title} />
      <div className="min-h-screen bg-white flex flex-col sm:flex-row mx-8 md:mx-24">
        <div className="md:absolute right-16 flex flex-col justify-center py-4 sm:px-6 lg:px-8">
          <div className="mt-12 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="md:bg-white dark:bg-gray-800 py-8 px-4 mt-12 md:shadow-lg md:border md:border-gray-100 mb-12 sm:rounded-2xl sm:px-10 md:mr-12">
              <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <div className="flex justify-center">
                  <Link className="flex items-center text-white" to="/">
                    <img className="h-10 w-auto" src={logo} alt="finablr" />
                  </Link>
                </div>

                <h2 className="mt-3 text-center text-xl font-extrabold text-gray-900 dark:text-white">
                  {title}
                </h2>
              </div>
              <div className="my-3">{children}</div>
              <div className="mt-4 text-xs text-center text-gray-500">
                Enabling Finance Limited © 2024 All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
