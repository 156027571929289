import { Button } from 'components/Elements';
import { Head } from 'components/Head';
import { WebLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { LoginForm } from 'features/auth/components/LoginForm';

export const Landing = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const handleStart = () => {
      if (user) {
        navigate('/app');
      }
    };
    handleStart();
  }, []);

  return (
    <WebLayout>
      <Head title="FinablrSign" description="Electronic Contract Signing" />
      <div className="md:w-1/3 mx-auto border border-gray-300  rounded-md bg-white p-8">
        <LoginForm />
      </div>
    </WebLayout>
  );
};
