import React from 'react';
import GooglePlayStore from 'assets/google-play.png';
import AppleStore from 'assets/app-store.png';
import { Mail, MapPin, Phone } from 'react-feather';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className="bg-gray-800 contract py-8 px-12 md:px-48">
      <div className="container mx-auto flex flex-wrap justify-between items-center">
        <div className="w-full md:w-1/2 md:pr-12 mb-8 md:mb-0">
          <div className="text-white text-lg mb-6">Contact Us</div>
          <div className="flex flex-col space-y-4">
            <div className="flex items-center space-x-2">
              <Phone className="h-6 w-6 text-white" />
              <span className="text-white">+256 706 400 057</span>
            </div>
            <div className="flex items-center space-x-2">
              <Mail className="h-6 w-6 text-white" />
              <span className="text-white">operations@finablr.app</span>
            </div>
            <div className="flex items-start space-x-2">
              <MapPin className="h-6 w-6 text-white" />
              <div className="text-white">
                P.O Box 134920, Kampala <br />
                Plot 14051, Twin Plaza, Mbogo Road <br />
                Kira town.
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 flex justify-center md:justify-end">
          <div className="flex items-center space-x-6">
            <a href="#">
              <img src={GooglePlayStore} alt="Google Play Store" className="h-12" />
            </a>
            <a href="#">
              <img src={AppleStore} alt="Apple App Store" className="h-12" />
            </a>
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-8 text-center text-white">
        <p className="text-sm">
          &copy; 2024 by Enabling Finance Ltd. All rights reserved.{' '}
          <Link to="/terms-and-conditions">Terms and Conditions</Link> |{' '}
          <Link to="/lender-policy">Lender Policy</Link> |{' '}
          <Link to="/borrower-policy">Borrower Policy</Link> |{' '}
          <Link to="/general-policy">General Policy</Link>
        </p>
        <p className="text-xs">A member company of Ma Wan Corporation.</p>
      </div>
    </footer>
  );
};
