import { Menu, Transition } from '@headlessui/react';
import { UserIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { useAuth } from 'lib/auth';
import React from 'react';
import { LogOut } from 'react-feather';
import { Link } from 'react-router-dom';

export const UserNavigation = () => {
  const { user, logout } = useAuth();

  const userNavigation = [
    {
      name: 'Sign out',
      icon: <LogOut className="h-4" />,
      to: '',
      onClick: () => {
        logout();
      },
    },
  ].filter(Boolean);

  return (
    <Menu as="div" className="ml-3 relative contract">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="max-w-xs  bg-white border border-gray-200 p-2 flex items-center text-sm rounded-lg focus:none">
              <span className="sr-only">Open user menu</span>
              <UserIcon className="h-4 w-4 rounded-full" />{' '}
              <span className="mx-2">{user.basicInformation?.firstName}</span>
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 dark:bg-gray-700 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      onClick={item.onClick}
                      to={item.to}
                      className={clsx(
                        `flex flex-row items-center`,
                        active ? 'bg-gray-100 dark:bg-gray-700' : '',
                        'block px-4 py-2 text-sm text-gray-700 dark:text-gray-400'
                      )}
                    >
                      {item.icon}
                      <span className="ml-2">{item.name}</span>
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
