import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
} from 'firebase/auth';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyD9xjdCVpAVj0s6_yPMf679iHgSNVfSUeQ',
  authDomain: 'katale-17c66.firebaseapp.com',
  databaseURL: 'https://katale-17c66.firebaseio.com',
  projectId: 'katale-17c66',
  storageBucket: 'katale-17c66.firebasestorage.app',
  messagingSenderId: '379782678286',
  appId: '1:379782678286:web:ebe6afd93343cfb2ef795f',
  measurementId: 'G-Y1W5RTYTNN',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Social providers
const providers = {
  google: new GoogleAuthProvider(),
  facebook: new FacebookAuthProvider(),
  twitter: new TwitterAuthProvider(),
};

/**
 * Handles social login with the specified provider.
 * @param {string} providerName - The name of the provider ('google', 'facebook', 'twitter').
 * @returns {Promise<Object>} - Returns the user object on success.
 */
export const socialLogin = async (providerName) => {
  const provider = providers[providerName];
  if (!provider) throw new Error('Unsupported provider');

  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    // Token for backend verification (if needed)
    const token = await user.getIdToken();

    return {
      user: {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
      },
      token,
    };
  } catch (error) {
    throw new Error(error.message);
  }
};
