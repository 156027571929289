import logo from 'assets/logo.svg';
import { Link } from 'react-router-dom';

export const Logo = () => {
  return (
    <Link to="." className="flex flex-row items-center contract space-x-2">
      <img className="h-8" src={logo} alt="Workflow" />
      <div className="font-extrabold">
        Finablr<span className="text-primary-100">Sign</span>{' '}
      </div>
    </Link>
  );
};
