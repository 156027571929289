import PropTypes from 'prop-types';
import * as React from 'react';
import { UserNavigation } from './components/UserNavigation';
import { useAuth } from 'lib/auth';
import { Logo } from './components/Logo';
import notificationSound from 'assets/sounds/new_notification.wav';

import { useEffect } from 'react';
import { useLiveNotificationStore } from 'stores/liveNotifications';

export const MainLayout = ({ children }) => {
  const { user } = useAuth();

  const { unreadCount } = useLiveNotificationStore();
  const bell = new Audio(notificationSound);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  useEffect(() => {
    if (unreadCount > 0) {
      bell.play();
    }
  }, [unreadCount]);

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <div className="bg-gray-100 flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative bg-white px-4 md:px-12 z-10 items-center flex-shrink-0 flex py-4 shadow">
          <Logo />

          <div className="flex-1 px-4 flex justify-end">
            <div className="ml-4 flex items-center md:ml-6">
              <UserNavigation />
            </div>
          </div>
        </div>
        <main className="flex-1 relative overflow-y-auto focus:outline-none flex flex-col justify-between">
          {/* The children content */}
          <div>{children}</div>

          {/* The footer content */}
          <footer className="bg-white contract shadow p-6">
            <div className="px-6 sm:px-10 text-xs text-gray-800 truncate overflow-hidden text-center">
              © {currentYear}, Enabling Finance Limited. or its affiliates. All rights reserved.
            </div>
            <div className="px-6 sm:px-10 text-xs text-gray-800 truncate overflow-hidden text-center">
              <a href="/terms-and-conditions">Terms and Conditions</a> .{' '}
              <a href="/general-policy">General Policy</a>
            </div>
          </footer>
        </main>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
